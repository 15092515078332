import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loading from './views/Notifications/Loading';
import { retry } from './utils';
import './App.scss';

// Containers
const DefaultLayout = React.lazy(() =>
  retry(() => import('./containers/DefaultLayout'))
);

// Pages
const Login = React.lazy(() => retry(() => import('./views/Pages/Login')));

const Register = React.lazy(() =>
  retry(() => import('./views/Pages/Clients/Register'))
);

const Page404 = React.lazy(() => retry(() => import('./views/Pages/Page404')));

const Page500 = React.lazy(() => retry(() => import('./views/Pages/Page500')));

export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Cadastro" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route
            path="/"
            render={(props) => {
              // backward compatibility
              if (props.location.hash === '#/brazilClients') {
                return <Redirect to="/register" />;
              }
              return <DefaultLayout {...props} />;
            }}
          ></Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
