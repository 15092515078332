export default function configReducer(
  state = {},
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case 'SET_SYSTEM_CONFIG':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
