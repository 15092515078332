import { combineReducers } from 'redux';
import login from './login';
import recaptcha from './recaptcha';
import config from './config';

const rootReducer = combineReducers({
  login,
  recaptcha,
  config,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
