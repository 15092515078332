import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import pt from './pt.json';

const resources = {
  en: {
    translation: {
      ...en,
    },
  },
  pt: {
    translation: {
      ...pt,
    },
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  nsSeparator: '',
  fallbackLng: 'en',
  resources,
});

export default i18n;
