import React from 'react';

export function formatMoney(amount, decimalCount = 2, language) {
  let decimal = '.';
  let thousands = ',';
  if (language.indexOf('pt') >= 0) {
    decimal = ',';
    thousands = '.';
  }
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substring(0, j) + thousands : '') +
      i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

export function textareaToJSXArray(text) {
  const textArray = [];
  if (text) {
    const splitArray = text.split('\n');

    for (let i = 0; i < splitArray.length; i++) {
      if (splitArray[i]) {
        textArray.push(splitArray[i]);
        textArray.push(<br key={`crlf_${i}`} />);
      }
    }
  }
  return textArray;
}

export function printMode(type) {
  const body = document.getElementsByClassName('header-fixed')[0];
  body.className = body.className + ' print-body';

  var style = document.createElement('style');

  if (type === 'A4') {
    style.innerHTML = `
    @page {
      size: 'A4';
      margin: 2% 2% 3% 2%;
    }
    `;
  } else if (type === 'TAG') {
    style.innerHTML = `
    @page {
      size: 'A4';
      margin: 6% 2.5% 3% 2%;
    }
    `;
  } else if (type === 'LETTER') {
    style.innerHTML = `
    @page {
      size: 'Letter';
      margin: 6% 0% 3% 0%;
    }
    `;
  }

  document.head.appendChild(style);
}

export function printPage(type, callback) {
  printMode(type);

  setTimeout(() => {
    window.print();

    if (callback) {
      callback();
    }
    const body = document.getElementsByClassName('header-fixed')[0];
    body.className = body.className.replace(/print-body/, '');
  }, 2000);
}

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function validateCPF(strCPF) {
  var sum;
  var mod;
  sum = 0;
  if (strCPF === '00000000000') return false;
  let i;

  for (i = 1; i <= 9; i++)
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  mod = (sum * 10) % 11;

  if (mod === 10 || mod === 11) mod = 0;
  if (mod !== parseInt(strCPF.substring(9, 10))) return false;

  sum = 0;
  for (i = 1; i <= 10; i++)
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  mod = (sum * 10) % 11;

  if (mod === 10 || mod === 11) mod = 0;
  if (mod !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function validateCNPJ(cnpj) {
  if (cnpj.length < 14) return false;

  return true;
}

export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function numberStr(n) {
  return n < 10 ? `0${n}` : n;
}

export function buildPackagingInfo({ packagings, netWeight, t, i18n }) {
  packagings = packagings.map((p) => {
    p.measurements = `${formatMoney(
      p.width,
      2,
      i18n.language
    )}m X ${formatMoney(p.height, 2, i18n.language)}m X ${formatMoney(
      p.length,
      2,
      i18n.language
    )}m`;
    p.cubiMeters = p.width * p.height * p.length;
    return p;
  });
  const boxes = packagings.reduce(
    (acc, e) => {
      if (!acc[e.boxType].sizes[e.measurements]) {
        acc[e.boxType].sizes[e.measurements] = {
          count: 1,
          boxNumbers: [e.boxNumber],
        };
      } else {
        acc[e.boxType].sizes[e.measurements] = {
          count: acc[e.boxType].sizes[e.measurements].count + 1,
          boxNumbers: acc[e.boxType].sizes[e.measurements].boxNumbers.concat(
            e.boxNumber
          ),
        };
      }
      acc[e.boxType].boxes.push(e);
      return acc;
    },
    {
      CARDBOARD: {
        boxes: [],
        sizes: {},
      },
      WOOD: {
        boxes: [],
        sizes: {},
      },
      PLASTIC_BAG: {
        boxes: [],
        sizes: {},
      },
    }
  );

  const text = [
    `${t('TOTAL_NET_WEIGHT')}: ${formatMoney(netWeight, 2, i18n.language)}Kg`,
    <br key="a" />,
    `${t('TOTAL_GROSS_WEIGHT')}: ${formatMoney(
      packagings.reduce((acc, e) => acc + e.grossWeight, 0),
      2,
      i18n.language
    )}Kg`,
    <br key="b" />,
  ];

  if (boxes.CARDBOARD.boxes.length) {
    text.push(<br key="c" />);
    text.push(
      `${t('PACKAGES')}: ${numberStr(boxes.CARDBOARD.boxes.length)} ${
        boxes.CARDBOARD.boxes.length > 1
          ? t('CARDBOARD_BOXES')
          : t('CARDBOARD_BOX')
      }`
    );
    text.push(<br key="d" />);
    Object.keys(boxes.CARDBOARD.sizes).forEach((measurements, i) => {
      const { count, boxNumbers } = boxes.CARDBOARD.sizes[measurements];
      text.push(
        `${numberStr(count)} ${
          count > 1 ? t('CARDBOARD_BOXES') : t('CARDBOARD_BOX')
        } ${t('WITH')} ${measurements}${
          count > 1 ? ` ${t('EACH_ONE')}` : ''
        } (${t('BOXES')}: ${boxNumbers.sort().join(', ')})`
      );
      text.push(<br key={`${i}_e`} />);
    });
    text.push(<br key="f" />);
  }

  if (boxes.PLASTIC_BAG.boxes.length) {
    if (!boxes.CARDBOARD.boxes.length) {
      text.push(<br key="k" />);
    }
    text.push(
      `${t('PACKAGES')}: ${numberStr(boxes.PLASTIC_BAG.boxes.length)} ${
        boxes.PLASTIC_BAG.boxes.length > 1
          ? t('PLASTIC_BAGS')
          : t('PLASTIC_BAG')
      }`
    );
    text.push(<br key="l" />);
    Object.keys(boxes.PLASTIC_BAG.sizes).forEach((measurements) => {
      const { count, boxNumbers } = boxes.PLASTIC_BAG.sizes[measurements];
      text.push(
        `${numberStr(count)} ${
          count > 1 ? t('PLASTIC_BAGS') : t('PLASTIC_BAG')
        } ${t('WITH')} ${measurements}${
          count > 1 ? ` ${t('EACH_ONE')}` : ''
        } (${t('BAGS')}: ${boxNumbers.sort().join(', ')})`
      );
      text.push(<br key="m" />);
    });
    text.push(<br key="n" />);
  }

  if (boxes.WOOD.boxes.length) {
    if (!boxes.CARDBOARD.boxes.length && !boxes.PLASTIC_BAG.boxes.length) {
      text.push(<br key="g" />);
    }
    text.push(
      `${t('PACKAGES')}: ${numberStr(boxes.WOOD.boxes.length)} ${
        boxes.WOOD.boxes.length > 1 ? t('PLYWOOD_BOXES') : t('PLYWOOD_BOX')
      } - ${t('ACCORDING_ISPM')}`
    );
    text.push(<br key="h" />);
    Object.keys(boxes.WOOD.sizes).forEach((measurements, i) => {
      const { count, boxNumbers } = boxes.WOOD.sizes[measurements];
      text.push(
        `${numberStr(count)} ${
          count > 1 ? t('PLYWOOD_BOXES') : t('PLYWOOD_BOX')
        } ${t('WITH')} ${measurements}${
          count > 1 ? ` ${t('EACH_ONE')}` : ''
        } (${t('BOXES')}: ${boxNumbers.sort().join(', ')})`
      );
      text.push(<br key={`i_${i}`} />);
    });
    text.push(<br key="j" />);
  }

  text.push(
    `${t('CUBIC_METERS')}: ${formatMoney(
      packagings.reduce((acc, e) => acc + e.cubiMeters, 0),
      6,
      i18n.language
    )}m³`
  );
  text.push(<br key="w" />);
  text.push(`${t('MARKS_NUMBERS')}: BRASIL / MB 1 / ${packagings.length}`);
  text.push(<br key="y" />);
  text.push(`${t('ORIGIN')}: ${t('BRAZIL')}`);
  return text;
}

function formatAddress(country, address, number) {
  if (country !== 'Brazil') {
    return `${number ? number : ''} ${address ? address : ''}`;
  } else {
    return `${address ? address : ''} ${number ? number : ''}`;
  }
}

export function getAddress(order) {
  return [
    <strong key={`strong_${order.clientName}`}>{order.clientName}</strong>,
    <br key="a" />,
    formatAddress(order.countryName, order.clientAddress, order.clientNumber),
    order.clientComplement ? ` ${order.clientComplement}` : '',
    order.clientNeighborhood ? `, ${order.clientNeighborhood}` : '',

    <br key="b" />,
    `${order.clientZipCode ? order.clientZipCode + ' ' : ''}${
      order.clientCity ? order.clientCity : ''
    } ${order.clientState ? ` - ${order.clientState}` : ''}`,
    <br key="c" />,
    order.countryName,
  ];
}

export function isWireTransfer(customsModeOfPayment) {
  return (
    customsModeOfPayment != null &&
    (customsModeOfPayment === 'AT_SIGHT_WIRE_TRANSFER' ||
      customsModeOfPayment === 'IN_ADVANCE_WIRE_TRANSFER' ||
      customsModeOfPayment === 'T_T_WITHIN_15_DAYS_AWB' ||
      customsModeOfPayment === 'WIRE_TRANSFER_WITHIN_30_DAYS_INVOICE')
  );
}

export async function downloadZPL(
  internal,
  { extended, clientName, invoiceNumber, orderProducts, number }
) {
  try {
    const zplContent = buildZPLFile(internal, {
      extended,
      clientName,
      invoiceNumber,
      orderProducts,
      number,
    });

    var link = document.createElement('a');
    link.setAttribute('href', zplContent);
    link.setAttribute('download', `${clientName}.zpl`);

    link.click();
  } catch {}
}

export async function downloadZPLProduction({
  clientName,
  orderProducts,
  clientCountry,
  invoiceNumber,
}) {
  try {
    const zplContent = buildZPLFileProduction({
      clientName,
      orderProducts,
      clientCountry,
      invoiceNumber,
    });

    var link = document.createElement('a');
    link.setAttribute('href', zplContent);
    link.setAttribute('download', `production_${clientName}.zpl`);

    link.click();
  } catch {}
}

// /**
//  * Convert base64url to uuid
//  *
//  * @example in: `8yqR2seZThOqF4xNngMjyQ` out: `f32a91da-c799-4e13-aa17-8c4d9e0323c9`
//  */
// export function base64toUUID(base64): UUID {
//   const hex = Buffer.from(base64, 'base64url').toString('hex');

//   return `${hex.substring(0, 8)}-${hex.substring(8, 12)}-${hex.substring(
//     12,
//     16,
//   )}-${hex.substring(16, 20)}-${hex.substring(20)}`;
// }

function buildTag(
  internal,
  { orderProduct, clientName, invoiceNumber, number }
) {
  const sewingCase = isSewingCase(orderProduct);

  let zplContent = `^XA
        ^CI28
        ^CF0,30
        ^FB450,9,0,L`;

  zplContent += '\\^FO30,50^FD';

  if (clientName && !internal) {
    zplContent += `${clientName}`;
  }

  if (orderProduct.clientPONumber && !internal) {
    zplContent += `\\& PO: ${orderProduct.clientPONumber}`;
  }

  if (internal) {
    zplContent += `Batch: ${invoiceNumber}`;
    zplContent += `\\&Ref. Number: ${orderProduct.referenceCode}`;
  } else {
    zplContent += `\\&Invoice Number: ${invoiceNumber}`;
    zplContent += `\\&\\&Ref. Number: ${orderProduct.referenceCode}`;
  }

  if (!internal) {
    if (sewingCase && number) {
      zplContent += `\\&\\&Item: ${number}/${orderProduct.quantity}`;
    } else if (number) {
      zplContent += `\\&Qty: ${number}`;
    } else {
      zplContent += `\\&Qty: ${orderProduct.quantity}`;
    }
  } else {
    zplContent += '^FS';
    zplContent += '^CFN,20^FO30,250^FD';

    if (sewingCase && number) {
      zplContent += `ID: ${orderProduct.id} / ${number}`;
    } else {
      zplContent += `ID: ${orderProduct.id}`;
    }
  }

  zplContent += '^FS';

  let urlParams;
  if (!number) {
    urlParams = `&q=${orderProduct.quantity}`;
  } else {
    if (sewingCase) {
      urlParams = `&c=${number}`;
    } else {
      urlParams = `&q=${number}`;
    }
  }

  if (orderProduct.websiteUrl) {
    zplContent += `^FO515,30^BQN,2,5^FDMA https://mbcases.com/portfolio${orderProduct.websiteUrl}?id=${orderProduct.id}${urlParams}^FS`;
  } else {
    zplContent += `^FO515,30^BQN,2,5^FDMA https://mbcases.com?id=${orderProduct.id}${urlParams}^FS`;
  }

  zplContent += '^FO30,320^GB730,3,3^FS';

  zplContent += `^CF0,20
        ^FO50,340^FDEN^FS
        ^CFN,20
        ^FB660,5,0,L
        ^FO100,330^FD${orderProduct.fullDescriptionEnglish}^FS`;

  zplContent += '^FO30,440^GB730,3,3^FS';

  zplContent += `^CF0,20
         ^FO50,460^FDPT^FS
         ^CFN,20
         ^FB660,5,0,L
         ^FO100,450^FD${orderProduct.fullDescriptionPortuguese}^FS`;

  zplContent += '^XZ';

  return zplContent;
}

function buildTagProduction({
  orderProduct,
  clientName,
  clientCountry,
  invoiceNumber,
}) {
  let zplContent = `^XA
        ^CI28
        ^CF0,40
        ^FB430,3,0,L
        ^FO50,50^FD${clientName} - ${invoiceNumber}^FS`;

  zplContent += '^FS';

  if (orderProduct.websiteUrl) {
    zplContent += `^FO515,30^BQN,2,5^FDMA https://mbcases.com/portfolio${orderProduct.websiteUrl}?id=${orderProduct.id}^FS`;
  } else {
    zplContent += `^FO515,30^BQN,2,5^FDMA https://mbcases.com?id=${orderProduct.id}^FS`;
  }

  zplContent += `^CF0,25
        ^FB660,8,0,L
        ^FO50,300^FD${orderProduct.fullDescriptionProduction}^FS`;

  zplContent += '^FO30,480^GB730,3,3^FS';

  zplContent += `^CF0,30
        ^FO50,500^FD${
          clientCountry === 'DE' ? 'Vivo Importado' : 'Vivo Nacional'
        }^FS`;

  zplContent += '^XZ';

  return zplContent;
}

function buildZPLFile(
  internal,
  { extended, clientName, invoiceNumber, orderProducts, number }
) {
  return (
    'data:text/zpl;charset=utf-8,' +
    encodeURIComponent(
      orderProducts
        .map((orderProduct) => {
          if (extended) {
            if (isSewingCase(orderProduct)) {
              return Array(orderProduct.quantity)
                .fill(orderProduct)
                .map((each, i) =>
                  buildTag(internal, {
                    clientName,
                    invoiceNumber,
                    orderProduct: each,
                    number: i + 1,
                  })
                )
                .join('');
            } else if (!internal) {
              return buildTag(internal, {
                clientName,
                invoiceNumber,
                orderProduct,
                number: null,
              });
            }
          } else {
            return buildTag(internal, {
              clientName,
              invoiceNumber,
              orderProduct,
              number,
            });
          }
          return '';
        })
        .join('')
    )
  );
}

export function isSewingCase(orderProduct) {
  return (
    orderProduct.categories &&
    (orderProduct.categories.includes('CASE') ||
      orderProduct.categories.includes('CASE_COVER'))
  );
}

function buildZPLFileProduction({
  clientName,
  orderProducts,
  clientCountry,
  invoiceNumber,
}) {
  return (
    'data:text/zpl;charset=utf-8,' +
    encodeURIComponent(
      orderProducts
        .map((orderProduct) => {
          return Array(orderProduct.quantity)
            .fill(orderProduct)
            .map((each, i) =>
              buildTagProduction({
                clientName,
                orderProduct: each,
                number: i + 1,
                clientCountry,
                invoiceNumber,
              })
            )
            .join('');
        })
        .join('')
    )
  );
}
