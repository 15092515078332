export default function loginReducer(
  state = {
    accessToken: localStorage.getItem('access_token'),
    refreshToken: localStorage.getItem('refresh_token'),
    token: JSON.parse(localStorage.getItem('token') || '{}'),
    logout: false,
  },
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      return {
        ...state,
        ...action.payload,
        logout: false,
      };
    case 'LOGOUT':
      return {
        ...state,
        logout: true,
      };
    default:
      return state;
  }
}
