import React from 'react';
import { Spinner } from 'reactstrap';

type Props = {
  className?: string;
};

export default function Loading({ className }: Props) {
  return (
    <div className={className ? className : 'animated fadeIn pt-3 text-center'}>
      <Spinner color="danger" />
    </div>
  );
}
